<template>
    <div>
        <!-- Form Modal -->
        <b-modal
            id="modal-club-form"
            ref="myModal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            size="sm"
            @ok="submit"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="club.id === null">Add Club</h5>
                <h5 v-if="club.id !== null">Edit Club</h5>
                
                <feather-icon
                    class="ml-4 pr-0 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="close()"
                />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button
                    size="sm"
                    variant="primary"
                    @click="ok()"
                    :disabled="saving"
                >
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button
                    size="sm"
                    @click="cancel()"
                    variant="outline-secondary"
                >
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="12" md="12">
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                                <locationFilters
                                    size="sm"
                                    align="vertical"
                                    @filter="changeFormFilters"
                                />
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="3">
                                <b-form-group label-for="code" label="CODE">
                                    <validation-provider #default="{ errors }" name="code" rules="">
                                        <b-form-input
                                            id="code"
                                            name="code"
                                            v-model="club.code"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.code">{{ serverErrors.code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="9">
                                <b-form-group label-for="name" label="Club Name">
                                    <validation-provider #default="{ errors }" name="name" rules="">
                                        <b-form-input
                                            id="name"
                                            name="name"
                                            v-model="club.name"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>
                <location-filters
                    size="sm"
                    align="horizontal"
                    @filter="changeFilters"
                />
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button
                                variant="primary"
                                size="sm"
                                @click="invokeCreateForm()"
                            >
                                <span class="text-nowrap">Add Club</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(name)="data">
                    {{ data.item.name.toUpperCase() }}
                </template>

                <template #cell(growers)="data">
                    {{ data.item.growers }}
                </template>

                <template v-for="hierarchy in hierarchies" #cell(village)="data">
                  {{ data.item.address.location.name }}
                </template>

                <template #cell(sector)="data">
                    {{ data.item.sector.code.toUpperCase() }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
        BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { required } from '@validations'
    import store from '@/store'
    import { ref, onMounted, onUnmounted } from '@vue/composition-api'
    import clubsStoreModule from '@/views/cromis/clubs/clubsStoreModule'
    import useClubsList from '@/views/cromis/clubs/useClubsList'
    import LocationFilters from '@core/components/cromis/filters/LocationFilters.vue'


    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
            BSpinner,
            ValidationObserver, ValidationProvider,

            LocationFilters,
        },
        directives: {},

        setup(props, context) {
            const dataForm = ref(null)
            const myModal = ref(null)
            const saving = ref(false)
            const serverErrors = ref(null)
            const sectors = ref([])
            const formSectors = ref([])

            const user = ref(JSON.parse(localStorage.getItem('userData')))

            const levels = ref(user.value.levels)
            const hierarchies = ref([])

            const club = ref({
                id: null,
                code: null,
                name: null,
                location_id: null,
                sector_id: null,
            })

            const CROMIS_STORE_MODULE_NAME = 'cromis-club'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, clubsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(() => {
                if(levels.value.length > 0){
                    let area = levels.value[0]

                    if(area.children.length > 0){
                        while(area.children.length > 0){
                            hierarchies.value.push({ key: area.name.toLowerCase() })
                            area = area.children[0]
                        }

                        hierarchies.value.push({ key: area.name.toLowerCase() })
                    }
                    else{
                        hierarchies.value.push({ key: area.name.toLowerCase() })
                    }
                }
            })

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                // Filers
                locationFilter,
                sectorFilter,
            } = useClubsList()

            const changeFilters = (location) => {
                locationFilter.value = location !== null? location.id : null
            }

            const changeFormFilters = (location) => {
                club.value.location_id = location !== null? location.id : null
                locationFilter.value = location !== null? location.id : null
            }

            // Form Methods
            const invokeUpdateForm = (item) => {
                serverErrors.value = null

                club.value = {
                    id: item.id,
                    code: item.code,
                    location_id: item.address.location.id,
                    sector_id: item.sector != null? item.sector.id : null,
                    name: item.name,
                }

                myModal.value.show()
            }

            const invokeCreateForm = () => {
                club.value = {
                    id: null,
                    location_id: null,
                    sector_id: null,
                    code: null,
                    name: null,
                }

                myModal.value.show()
            }

            const isFormValid = async () => {
                let isValid = false 
                await dataForm.value.validate().then(success => {
                    isValid = success
                })

                return isValid
            }

            const submit = (bvModalEvt) => {
                bvModalEvt.preventDefault()

                serverErrors.value = null
                // Handle form submit
                if (club.value.id === null || club.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(club.value)
            }

            const handleCreate = async () => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                
                await store.dispatch('cromis-club/create', club.value)
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `The club has been added successfully!`,
                            showConfirmButton: true,
                            timer: 2000,
                            customClass: {
                                confirmButton: 'btn btn-outline-success',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-club/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to sector ${response.data.code} has been saved successfully!`,
                            showConfirmButton: true,
                            timer: 5000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-club/remove', id)
                            .then(response => {
                                refetch()
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: false,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                })
                            })
            }

            return {
                hierarchies,

                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                required,
                dataForm,
                myModal,
                saving,
                serverErrors,
                sectors,
                formSectors,
                levels,

                // Form
                club,
                invokeCreateForm,
                invokeUpdateForm,
                submit,
                isFormValid,
                handleCreate,
                handleUpdate,
                remove,

                // Filers
                changeFilters,
                changeFormFilters,
                locationFilter,
                sectorFilter,
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>